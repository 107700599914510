<template>
  <div class="w-4/6 mx-auto space-y-4">
    <div class="space-y-3 text-xl text-gray-600 font-bold flex justify-center items-center ">{{company.name}} Seasons</div>
    <div class="border-b-2 border-opacity-25 border-gray-500"/>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul class="divide-y divide-gray-200">
      <li v-for="(season, idx) in seasons" :key="idx" class="px-4 py-4 sm:px-6 flex justify-between ">
        <router-link class="text-gray-600 text-sm font-bold hover:text-black hover:font-bold" :to="`/seasons/${season.id}`">{{season.name}}</router-link>
        <router-link class="text-indigo-500 text-sm font-bold hover:text-indigo-900 hover:font-bold" :to="`/EditSeason/${season.id}`">Edit Season</router-link>
      </li>
    </ul>
  </div>
    <div class="flex justify-center items-center py-1">
      <router-link :to="{ name: 'AddSeason'}">
        <button type="button" class="inline-flex items-center px-4 py-2 border border-black shadow-sm bg-gray-400 rounded-md text-sm text-gray-100 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Add Season <div class="px-0.5"></div> <CloudIcon class="h-5 w-5 block"></CloudIcon>
        </button>
      </router-link>
  </div>
  </div>
</template>

<script>
import {computed} from "vue";
import { useStore } from 'vuex'
import {CloudIcon} from '@heroicons/vue/solid';

export default {
  name: "seasons",
  components: { CloudIcon },
  setup(){
    const store = useStore()
  return {
    company: computed(() => store.getters.getCompany),
    seasons: computed( () => store.getters.getCompanySeasons),
}}}

</script>

<style scoped>

</style>