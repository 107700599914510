<template>
  <div class="flex fixed bg-white shadow-xl rounded-3xl inset-x-64 top-40 z-30 border-2">
    <div class="m-4 w-full flex flex-col justify-between">
      <div class="flex h-1/3">
        <img v-if="selectedAvatar === ''" src="@/assets/avatar_placeholder.png" class="rounded-full h-28 w-28 flex items-center justify-center">
        <img v-else :src="selectedAvatar" class="h-28 w-28 rounded-full flex items-center justify-center">

        <input @input="$emit('search', searchStr)" v-model="searchStr" type="text" class="w-full border-none bg-transparent text-2xl tracking-widest border-transparent focus:outline-none focus:border-none" placeholder="Name" />
        <div v-if="!selectedId" class="m-4 w-full h-full">
          <ul class="shadow-xl">
            <AddMemberCard v-for="(member, idx) in members" @click="$emit('onSelect', member)" :key="idx" :member="member" />
          </ul>
        </div>
      </div>
      <div v-if="selectedId"  >
        <div v-for="(form, idx) in formList" :key="idx" class="mt-4 flex justify-start align-center items-end">
          <div v-for="(field, el, subIdx) in form" :key="subIdx" >
            <label v-if="field.placeholder !== 'Name' && idx === 0" class="mx-2">{{field.placeholder}}</label>
            <input v-if="field.placeholder !== 'Name'" v-model="field.value" :type="field.type" class=" mx-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md" :placeholder="field.placeholder" />
          </div>
          <TrashIcon v-if="idx !== 0" @click="$emit('removeLine', idx)"  class="w-6 mb-2"/>
        </div>
        <button class="text-medium font-medium tracking-wider mx-4 my-4" @click="$emit('addNewLine')">+ Add role</button>
      </div>
      <div class="flex justify-end items-center mt-6">
        <button v-if="selectedId" :disabled="formFilledOut" @click="$emit('submitForm'); $emit('close')" type="button" class="mx-2 mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Send Invitation
        </button>
        <button @click="$emit('close')" type="button" class="mx-2 mt-4 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
      </div>

    </div>

  </div>
</template>

<script>

import AddMemberCard from "@/components/widgets/addMemberWidget/AddMemberCard";
import { TrashIcon } from '@heroicons/vue/outline/esm'
import {ref} from "vue";
export default {
  name: "AddMemberWidget",
  components: { AddMemberCard, TrashIcon},
  props: {
    people: {
      type: Array
    },
    formList: {
      type: Array
    },
    selectedId: {
      type: String
    },
    selectedAvatar: String,
    searchVal: String,
    selectedName: String
  },
  setup(props) {

    const searchStr = ref(props.selectedName)
    return {
      searchStr
    }
  },
  computed: {
    formFilledOut() {
      let valid = false
      for(let i=0; i < this.formList.length; i++) {
        let form = this.formList[i]
        for(let item in form){
          if(item === 'name' || item === 'left') continue
          if (form[item].value === '') valid = true
        }
      }
      return valid

    },
    members() {
      // Adding active attribute to each member, to be used in css highlighting
      return this.$props.people.map(person => {
        if(person.id === this.$props.selectedId) {
          return { ...person, active:true}
        }
        return { ...person, active:false}
      })
    }

  },
  watch: {
    people(){
      // if(value.length === 1) this.$emit('onPassiveSelect', value[0])
    },
    selectedName(val){
      this.searchStr = val
    }
  },
  unmounted() {
    this.$store.commit('resetAddMemberWidgetState')
  }
}
</script>
