<template>
  <li class="p-4 flex border-b-2" :class="[member.active ? 'border-indigo-200 border-b-2 border-t-2' : '']">
    <img class="h-10 w-10 rounded-full" :src="member.image" alt="" />
    <div class="ml-3">
      <p class="text-sm font-medium text-gray-900">{{ member.name }}</p>
      <p class="text-sm text-gray-500">{{ member.email }}</p>
    </div>
  </li>
</template>

<script>

export default {
  name: "AddMemberCard",
  props: {
    member: {
      type: Object
    }
  },
  setup() {
  }
}
</script>
