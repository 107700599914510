<template>
  <EditCompanyMenu />
</template>

<script>
import EditCompanyMenu from "@/components/companies/editCompany/EditCompanyMenu";
import { useStore } from 'vuex'
import {useRoute, useRouter} from "vue-router";
export default {
  name: 'EditCompany',
  components: { EditCompanyMenu },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    store.dispatch('fetchCompany', route.params.id)
    if(!(store.getters.getId === store.getters.getCompanyCreatedBy)) 
        router.push({name: "Company", params:{id:route.params.id}})

    return { store }
  }
}
</script>
