<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <div class="flex items-start flex-col">
      <AddMemberWidget v-if="addMemberWidgetOn"
                       @OnPassiveSelect="onPassiveSelect"
                       @onSelect="onSelectPerson"
                       @search="onSearchAddPeopleForm"
                       @submit-form="submitAddPeopleForm"
                       @close="addMemberWidgetOn=false; clearForm()"
                       @addNewLine="addNewLine"
                       @removeLine="removeLine"
                       :people="people"
                       :formList="formList"
                       :selectedId="selectedId"
                       :selectedAvatar="selectedAvatar"
                       :selectedName="selectedName"
      />
      <DynamicTable @next="onNextPage" @prev="onPreviousPage" class="w-full mt-10 " :link="link" :update="update" :button="button">
        <button class="text-lg font-medium tracking-wider mx-4" @click="addMemberWidgetOn=true">+ Add member</button>
      </DynamicTable>
    </div>
  </div>
</template>

<script>
// Component for managing company members

import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import {useStore} from "vuex";
import {computed, reactive, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import AddMemberWidget from "@/components/widgets/addMemberWidget/AddMemberWidget";
export default {
  name: "CompanyTeam",
  components: {AddMemberWidget, DynamicTable },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    store.dispatch('dynamicTableLoadUnifiedCompanyMembers')

    // =================Dynamic Table================= //
    function onProfileView(compMemid){
      router.push({ name: 'Profile', params: { id: findProfileId(compMemid)[0].profileId }})
    }

    function findProfileId(id){
      return store.getters.getDynamicTableItems.filter( el => el.id === id)
    }

    async function update(){
      await store.dispatch('dynamicTableLoadUnifiedCompanyMembers')
    }

    async function onNextPage() {
      store.dispatch('companyMembersUnifiedPageNext')
    }

    async function onPreviousPage() {
      store.dispatch('companyMembersUnifiedPagePrevious')
    }
    // =============================================== //
    // =================Add People Form=============== //

    function onMemberDelete(id){
      console.log('DELETE', id)
      // store.dispatch('deleteCompanyMember', id)
    }

    function onMemberEdit(id){
      const member = store.getters.getCompanyMemberByProfileId(id)
      if(member){
        formList.value = []
        selectedId.value = member.member.id
        selectedAvatar.value = member.member.image
        selectedName.value = member.member.name
        addMemberWidgetOn.value = true

        for(let i=0; i < member.job.length; i++){
          let job = member.job[i]
          formList.value.push(reactive({
            name: {
              placeholder: "Name",
              value: member.member.name,
              type: 'text'
            },
            role: {
              placeholder: "Role",
              value: job.title,
              type: 'text'
            },
            department: {
              placeholder: "Department",
              value: job.department,
              type: 'text'
            },
            started: {
              placeholder: "Year from",
              value: job.dateStarted,
              type: 'date'
            },
            left: {
              placeholder: "Year to",
              value: job.dateLeft,
              type: 'date'
            },
            id: job.id
          }))
        }
      }

    }

    function removeLine(idx){
      if(formList.value[idx].id) onMemberDelete(formList.value[idx].id)
      formList.value.splice(idx, 1)
    }

    function clearForm(){
      selectedAvatar.value = ''
      selectedId.value = null
      selectedName.value = ''
      formList.value = []
      addNewLine()
    }

    function addNewLine(){
      formList.value.push(reactive({
        name: {
          placeholder: "Name",
          value: '',
          type: 'text'
        },
        role: {
          placeholder: "Role",
          value: '',
          type: 'text'
        },
        department: {
          placeholder: "Department",
          value: '',
          type: 'text'
        },
        started: {
          placeholder: "Year from",
          value: '',
          type: 'date'
        },
        left: {
          placeholder: "Year to",
          value: '',
          type: 'date'
        }
      }))
    }

    function submitAddPeopleForm() {
      for(let i=0; i < formList.value.length; i++){
        let form = formList.value[i]
        if (form.id){
          console.log('HANDLE EDITS', form)
        } else {

          store.dispatch('saveCompanyMember', {
            profileId:selectedId.value,
            companyId:route.params.id,
            role:form.role.value,
            department:form.department.value,
            started:form.started.value,
            left:form.left.value === '' ? null : form.left.value
          })
        }

      }
    }

    function onSelectPerson(person){
      selectedId.value = person.id
      selectedAvatar.value = person.image
      selectedName.value = person.name
    }

    function onPassiveSelect(person){
      selectedId.value = person.id
      selectedAvatar.value = person.image
      selectedName.value = person.name
    }

    async function onSearchAddPeopleForm(val) {
      store.commit('setAddMemberWidgetSearchParam', val)
      const res = await store.dispatch('fetchPeople', store.getters.getAddMemberWidgetParams)
      store.commit('setAddMemberWidgetValues', res)
      selectedId.value = null
      selectedAvatar.value = ''
    }
    // =============================================== //
    const formList = ref([])
    addNewLine()

    let selectedId = ref(null)
    let selectedAvatar = ref('')
    let selectedName = ref('')
    let addMemberWidgetOn = ref(false)
    return {
      store,
      route,
      formList,
      selectedId,
      selectedAvatar,
      selectedName,
      addMemberWidgetOn,
      addNewLine,
      removeLine,
      clearForm,
      update,
      onNextPage,
      onPreviousPage,
      submitAddPeopleForm,
      onSelectPerson,
      onPassiveSelect,
      onSearchAddPeopleForm,

      link: computed(() => {
        return {func: onProfileView, label: "View Profile"}
      }),
      button: computed(() => {
        return {func:onMemberEdit, label: "Edit"}
      }),
      people: computed(() => store.getters.getCardFormattedPeople.slice(0, 4)),
    }
  },
  unmounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>
