<template>
  <div class="w-full">
    <div class="grid grid-cols-10">
      <div class="w-full grid col-span-10 bg-white py-8">
        <ProfileHeader :profile="headerInfo"/>
      </div>
      <ProfileNavbar class="col-start-3 col-span-6" @change="changeComponent" :navigation="navigation"/>
    <div class="col-start-3 col-span-7">
      <transition name="fade">
        <component :is="currentComponent" />
      </transition>
    </div>
    </div>
  </div>
</template>

<script>
// Component dealing with navigation within the edit company menu

import { KeyIcon, UserCircleIcon, UserGroupIcon,CloudIcon } from '@heroicons/vue/outline'
import { computed, ref } from "vue";
import { useStore } from "vuex";
import CompanyInfo from "@/components/companies/editCompany/CompanyInfo";
import CompanySeasons from "./CompanySeasons";
import CompanySettings from "@/components/companies/editCompany/CompanySettings";
import CompanyTeam from "@/components/companies/editCompany/CompanyTeam";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import ProfileHeader from "@/components/widgets/profileHeader/ProfileHeader";


const navigation = ref([
  { name: 'Public Info', onClick:function(){onNavClick(this, navigation.value)}, component: 'CompanyInfo', icon: UserCircleIcon, current: true },
  { name: 'Settings', onClick:function(){onNavClick(this, navigation.value)}, component: 'CompanySettings', icon: KeyIcon, current: false },
  { name: 'Team', onClick:function(){onNavClick(this, navigation.value)}, component: 'CompanyTeam', icon: UserGroupIcon, current: false },
  { name: 'Seasons', onClick:function(){onNavClick(this, navigation.value)}, component: 'CompanySeasons', icon: CloudIcon, current: false },
])

function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

export default {
  name: 'EditCompanyMenu',
  components: { CompanyInfo, CompanySeasons, CompanySettings, CompanyTeam, ProfileNavbar, ProfileHeader },

  setup() {
    const store = useStore()
    let currentComponent = ref('CompanyInfo')
    return {
      navigation,
      currentComponent,
      changeComponent(component) {
        currentComponent.value = component
      },
      headerInfo: computed(() => store.getters.getCompanyHeader),
    }
  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++){
      this.navigation[i].current = false
    }
    this.navigation[0].current = true
  }
}
</script>